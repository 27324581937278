import { z } from "zod"

export const sbStoryLinkSchema = z.object({
  linktype: z.literal("story"),
  story: getSbStoryLinkStorySchema({}),
  cached_url: z.string().optional(),
})

const sbUrlLinkSchema = z.object({
  url: z.string(),
  linktype: z.literal("url"),
  fieldtype: z.literal("multilink"),
  target: z.enum(["_blank", "_self"]).nullish(),
})

export const shopifyLinkSchema = z.object({
  id: z.string(),
  handle: z.string(),
})

export const linkSchema = z.union([sbStoryLinkSchema, sbUrlLinkSchema, shopifyLinkSchema])

export type LinkFromSchema = z.infer<typeof linkSchema>

export type SbLinkFromSchema = z.infer<typeof sbStoryLinkSchema>
export type ShopifyLinkFromSchema = z.infer<typeof shopifyLinkSchema>

export function getSbStoryLinkStorySchema<Obj extends z.ZodRawShape>(contentSchema: Obj) {
  return z
    .object({
      slug: z.string(),
      name: z.string(),
      content: z
        .object({
          component: z.string().optional(),
        })
        .extend(contentSchema)
        .optional(),
      translated_slugs: z
        .array(z.object({ lang: z.string(), path: z.string(), name: z.string().nullable() }))
        .optional(),
      // fix
      alternates: z.any(),
    })
    .optional()
    .transform((value) => {
      if (!value) {
        return value
      }

      return {
        ...value,
        translated_slugs: value?.translated_slugs ?? value?.alternates ?? [],
      }
    })
}
