import type { GetUnionByKey, ObjectValues } from "~/@types/generics"
import type { SbFolder } from "~/lib/storyblok/types"

const sbPagesComponent = [
  "universal_page",
  "home_page",
  "collection_page",
  "not_found",
  "legals_page",
  "search_page",
  "account_addresses_page",
  "account_informations_page",
  "account_orders_page",
  "login_page",
  "reset_password_page",
  "forgot_password_page",
  "register_page",
  "enable_account_page",
  "search_page",
  "blog_home_page",
  "blog_article_page",
  "product_page",
] as const

const sbGlobalsComponent = [
  "blogCategories",
  "account",
  "dictionary",
  "metas",
  "navigation",
  "uspBanner",
  "popinNewsletter",
  "popinBackInStock",
  "productReassurance",
  "panelSizeChart",
  "panelMaterials",
  "pdpEditoLayouts",
  "footer",
  "cart",
  "internalRedirects",
  "product",
] as const

const sbAuthenticationSlugs = {
  login: "login",
  register: "register",
  reset_password: "reset-password",
  forgot_password: "forgot-password",
  enable_account: "enable-account",
} as const

export type SbAuthenticationSlugs = (typeof sbAuthenticationSlugs)[keyof typeof sbAuthenticationSlugs]
export type SbPagesComponent = (typeof sbPagesComponent)[number]
export type SbGlobalsComponent = (typeof sbGlobalsComponent)[number]

export const SB_AUTHENTICATION_PAGES = {
  login_page: {
    slug: `pages/authentication/${sbAuthenticationSlugs.login}`,
    rootSlug: `pages/authentication/${sbAuthenticationSlugs.login}`,
    sbPagesComponent: "login_page",
  },
  register_page: {
    slug: `pages/authentication/${sbAuthenticationSlugs.register}`,
    rootSlug: `pages/authentication/${sbAuthenticationSlugs.register}`,
    sbPagesComponent: "register_page",
  },
  reset_password_page: {
    slug: `pages/authentication/${sbAuthenticationSlugs.reset_password}`,
    rootSlug: `pages/authentication/${sbAuthenticationSlugs.reset_password}`,
    sbPagesComponent: "reset_password_page",
  },
  forgot_password_page: {
    slug: `pages/authentication/${sbAuthenticationSlugs.forgot_password}`,
    rootSlug: `pages/authentication/${sbAuthenticationSlugs.forgot_password}`,
    sbPagesComponent: "forgot_password_page",
  },
  enable_account_page: {
    slug: `pages/authentication/${sbAuthenticationSlugs.enable_account}`,
    rootSlug: `pages/authentication/${sbAuthenticationSlugs.enable_account}`,
    sbPagesComponent: "enable_account_page",
  },
} satisfies Partial<SbFolder<"pages", SbPagesComponent>>

export const SB_ACCOUNT_PAGES = {
  account_informations_page: {
    slug: `pages/account/`,
    rootSlug: `pages/account/`,
    sbPagesComponent: "account_informations_page",
  },
  account_addresses_page: {
    slug: `pages/account/my-addresses`,
    rootSlug: `pages/account/my-addresses`,
    sbPagesComponent: "account_addresses_page",
  },
  account_orders_page: {
    slug: `pages/account/my-orders`,
    rootSlug: `pages/account/my-orders`,
    sbPagesComponent: "account_orders_page",
  },
} satisfies Partial<SbFolder<"pages", SbPagesComponent>>

export const SB_PAGES = {
  universal_page: {
    slug: "pages/universals/:slug",
    rootSlug: "pages/universals",
    sbPagesComponent: "universal_page",
  },
  collection_page: {
    slug: "pages/collections/:slug",
    rootSlug: "pages/collections",
    sbPagesComponent: "collection_page",
  },
  product_page: {
    slug: "pages/products/:slug",
    rootSlug: "pages/products",
    sbPagesComponent: "product_page",
  },
  legals_page: {
    slug: "pages/legals/:slug",
    rootSlug: "pages/legals",
    sbPagesComponent: "legals_page",
  },
  home_page: {
    slug: "pages/",
    rootSlug: "pages/",
    sbPagesComponent: "home_page",
  },
  not_found: {
    slug: "pages/404",
    rootSlug: "pages/404",
    sbPagesComponent: "not_found",
  },
  search_page: {
    slug: "pages/search",
    rootSlug: "pages/search",
    sbPagesComponent: "search_page",
  },
  blog_home_page: {
    slug: "pages/le-journal",
    rootSlug: "pages/le-journal",
    sbPagesComponent: "blog_home_page",
  },
  blog_article_page: {
    slug: "pages/journal/:slug",
    rootSlug: "pages/journal",
    sbPagesComponent: "blog_article_page",
  },
  ...SB_AUTHENTICATION_PAGES,
  ...SB_ACCOUNT_PAGES,
} satisfies Partial<SbFolder<"pages", SbPagesComponent>>

export const SB_SITEMAP_REPEATABLE_PAGES = [
  SB_PAGES.collection_page,
  SB_PAGES.legals_page,
  SB_PAGES.universal_page,
] as const

export type SbSitemapPagesComponent = (typeof SB_SITEMAP_REPEATABLE_PAGES)[number]["sbPagesComponent"]

export const SB_GLOBALS = {
  metas: {
    slug: "globals/metas",
    sbPagesComponent: "metas",
  },
  dictionary: {
    slug: "globals/dictionary",
    sbPagesComponent: "dictionary",
  },
  navigation: {
    slug: "globals/navigation",
    sbPagesComponent: "navigation",
  },
  productReassurance: {
    slug: "globals/product_reassurance",
    sbPagesComponent: "productReassurance",
  },
  popinNewsletter: {
    slug: "globals/popin_newsletter",
    sbPagesComponent: "popinNewsletter",
  },
  popinBackInStock: {
    slug: "globals/popin_back_in_stock",
    sbPagesComponent: "popinBackInStock",
  },
  uspBanner: {
    slug: "globals/usp_banner",
    sbPagesComponent: "uspBanner",
  },
  panelSizeChart: {
    slug: "globals/panel_size_chart",
    sbPagesComponent: "panelSizeChart",
  },
  panelMaterials: {
    slug: "globals/material-panels/:slug",
    rootSlug: "globals/material-panels",
    sbPagesComponent: "panelMaterials",
  },
  footer: {
    slug: "globals/footer",
    sbPagesComponent: "footer",
  },
  cart: {
    slug: "globals/cart",
    sbPagesComponent: "cart",
  },
  internalRedirects: {
    slug: "globals/internal-redirects",
    sbPagesComponent: "internalRedirects",
  },
  account: {
    slug: "globals/account",
    sbPagesComponent: "account",
  },
  product: {
    slug: "globals/product",
    sbPagesComponent: "product",
  },
  pdpEditoLayouts: {
    slug: "globals/layouts/pdp",
    sbPagesComponent: "pdpEditoLayouts",
  },
  blogCategories: {
    slug: "globals/blog-categories/:slug",
    rootSlug: "globals/blog-categories",
    sbPagesComponent: "blogCategories",
  },
} satisfies SbFolder<"globals", SbGlobalsComponent>

type SbStories = ObjectValues<typeof SB_PAGES> | ObjectValues<typeof SB_GLOBALS>

export type SbSlug = GetUnionByKey<SbStories, "slug">
export type SbPagesSlug = GetUnionByKey<ObjectValues<typeof SB_PAGES>, "slug">
