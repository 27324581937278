"use client"

import {
  forwardRef,
  type AnchorHTMLAttributes,
  type ButtonHTMLAttributes,
  type MouseEvent,
  type PropsWithChildren,
  type Ref,
} from "react"
import NextLink, { type LinkProps as NextLinkProps } from "next/link"
import { usePathname, useRouter } from "next/navigation"
import clsx from "clsx"

import type { Nullish } from "~/@types/generics"

export interface AbstractLinkProps extends Omit<NextLinkProps, "href">, PropsWithChildren {
  className?: string
  activeClassName?: string
  isExternal?: boolean
  href?: NextLinkProps["href"]
  rel?: string
  target?: string
  obfuscate?: boolean
  style?: React.CSSProperties
  title?: Nullish<string>
  disabled?: boolean
  htmlType?: ButtonHTMLAttributes<HTMLButtonElement>["type"]
  onMouseLeave?: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void
}

function LinkEntity(
  {
    className,
    activeClassName,
    href,
    children,
    isExternal,
    obfuscate,
    htmlType = "button",
    legacyBehavior: _legacyBehavior,
    prefetch: _prefetch,
    ...rest
  }: AbstractLinkProps,
  ref: Ref<HTMLButtonElement | HTMLAnchorElement>
): JSX.Element {
  const router = useRouter()
  const pathname = usePathname()
  const isActive = !isExternal && href === pathname

  if (obfuscate) {
    const onClick = (e: MouseEvent<HTMLElement>) => {
      rest?.onClick?.(e as MouseEvent<HTMLAnchorElement>)

      if (rest.target === "blank") {
        href && window.open(href as string, "_blank")
      } else {
        href && router.push(href as string)
      }
    }

    return (
      <button
        ref={ref as Ref<HTMLButtonElement>}
        className={clsx(className, {
          ...(activeClassName && {
            [activeClassName]: activeClassName && isActive,
          }),
        })}
        {...(rest as Omit<ButtonHTMLAttributes<HTMLButtonElement>, "href">)}
        type={htmlType}
        onClick={onClick}
      >
        {children}
      </button>
    )
  }

  if (href) {
    if (isExternal) {
      return (
        <a
          ref={ref as Ref<HTMLAnchorElement>}
          className={className}
          rel="noreferrer noopener"
          href={href as string}
          {...(rest as Omit<AnchorHTMLAttributes<HTMLAnchorElement>, "href">)}
        >
          {children}
        </a>
      )
    }

    return (
      <NextLink
        ref={ref as Ref<HTMLAnchorElement>}
        href={href}
        className={clsx(className, {
          ...(activeClassName && {
            [activeClassName]: activeClassName && isActive,
          }),
        })}
        {...(rest as Omit<NextLinkProps, "href">)}
      >
        {children}
      </NextLink>
    )
  }

  return (
    <button
      ref={ref as Ref<HTMLButtonElement>}
      className={clsx(className, {
        ...(activeClassName && {
          [activeClassName]: activeClassName && isActive,
        }),
      })}
      {...(rest as Omit<ButtonHTMLAttributes<HTMLButtonElement>, "href">)}
      type={htmlType}
    >
      <>
        {href}
        {children}
      </>
    </button>
  )
}

export const AbstractLink = forwardRef(LinkEntity)
